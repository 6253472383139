.selected{
   
    z-index: -1;

    background: transparent;
    box-shadow: inset 0 0 0 2px #fff;

    
    position: absolute;
    bottom: 0;
    border-radius: 40px !important;
    
}
.lang_selected{
    filter: drop-shadow(2px 4px 6px black);
    
}
.lang_not_selected{
    filter: brightness(0.6) blur(1px);
}
.button{
    display: contents;
    margin-left: 2px;
}

.CountryFlag{
    margin-left : 13px;

}

@media only screen and (max-width: 991px) {

    
    .selected{
    display: contents !important;
   color: black !important;
    font-size: x-large !important;
    
    }
    .header-nav .nav{
        background-color:  #0086f0;
    }

    .mo-left .header-nav.show{
        background-color: #0086f0;
}
    
    [class*="bg-"] .header-nav .nav > li > a {
        color: #fff;
    }
    .header-nav .nav > li .sub-menu li a{
        
        color: #fff;
    }
    .header-nav .nav > li .sub-menu li a:after, .header-nav .nav > li .mega-menu > li ul a:after{
        color: #fff;
    }
    .header-nav .nav > li.open > .sub-menu li.open > a > i, .header-nav .nav > li.open > a > i{
        background: #ffffffd1;
    }
    .side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before, .nav.navbar-nav li.open a i.fa-chevron-down:before{
        color: #0086f0;
    }

.traduction{
    margin-left: 30%;
}

}