html{
    overflow-x:hidden ;
  }
  body {
    overflow-x: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: 300;
    font-family: Raleway, sans-serif;
  }
  
  * {
    font-family: 'Oswald', sans-serif;
    /*letter-spacing: 0.75px;*/
  }
  
  
  /*
  *************************/
  /*    1 / NAVBAR       */
  /*************************
  */
  
  .navbar .navbar-nav .nav-link {
    line-height: 1.6;
    margin: 5px 3px;
    padding: .5rem 1rem;
    opacity: .8;
    text-transform: uppercase;
    font-weight: 600;
    height: fit-content;
    color: #f6f3ee;
  }
  .navbar-toggler {
    border: none;
  }
  .navbar{
    border: 0;
    font-size: 15px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    padding: 0;
    background: #1498ec;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    height: fit-content;
  }
  .navbar-transparent{
    background: transparent !important;
    padding-top: 25px;
    box-shadow: none;
    font-size: 15px;
  }
  @media (min-width: 770px) {
    .nav-link:hover{
      transform: scale(1.1);
    }
  }
  @media (max-width: 770px) {
    .navbar-brand {
      margin-left: 20px;
    }
    .navbar-toggler {
      margin-right: 20px;
    }
  }
  
  
  .navbar-transparent .navbar-nav .nav-link{
    color: #f6f3ee;
  }
  
  a {
    color: #FFF;
  }
  a:hover{
    color: white;
    background-color: transparent;
    text-decoration: none;
  }
  







/*
  *************************/
  /*    1 / FOOTER       */
  /*************************
  */


  .bg-footer{
    background-color: #81a0aa;
    background-attachment: fixed;
    background-size: cover;
    /*background-image: url("../../../assets/img/bgGray.webp");*/
}


.logo{
    position: relative;
    display: block;
    left : calc(50% - 45px);
    color: #ff6535;
}

.mapouter {
    /*position: relative;*/
    /*text-align: right;*/
    /*height: 220px;*/
    width: fit-content;
    margin: 0;
}
.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 220px;
    width: 90%;
}


.bg-dark-light {
    background: #323232 !important;
}
.bg-theme, .bg-dark, .bg-dark-light, .bg-secondary {
    color: #ffffff;
}
.widgets.footer-widgets .widget-title {
    color: #ffffff;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    position: relative;
    padding-bottom: 15px;
}

@media (max-width: 800px) {
    .widget-title{
        padding-top: 20px;
    }
}

.widgets.footer-widgets .widget-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 60px;
    background: #ff6535;
}

.widgets.footer-widgets .widget-title::before {
    content: '';
    position: absolute;
    display: block;
    width: 60%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    bottom: 0.5px;
    left: 0;
}



.widgets.footer-widgets .widget-quick-links ul {
    margin-top: -4px;
}
.widgets.footer-widgets .single-widget ul {
    padding-left: 0;
    margin-bottom: 0;
}

.widgets.footer-widgets .widget-quick-links ul li {
    display: block;
}
.widgets.footer-widgets .widget-quick-links ul li .link span{
    display: block;
    font-size: 15px;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 9px 0;
    width: 45%;
}
.widgets.footer-widgets .widget-quick-links ul li .link span:hover {
    color: rgba(255, 255, 255, 0.7);
    transform: scale(1.05);
}


.widgets.footer-widgets .single-widget ul li {
    list-style: none;
    font-family: "Raleway", sans-serif;
}



.hrCopyRights{
    margin-top: 7px;
    margin-bottom: 0;
    background-color: rgba(217, 217, 217, 0.6);

}
.social {
    display: flex;
    flex-direction: row;

}
.social a {
    width: 50px;
    height: 40px;
    line-height: 50px;
    color: #ff6535; 
    font-size: 20px;
    margin: 5px 5px;
    padding : 0 15px;
}
.social a:hover {
    color: #fff;
}
.social a i:hover{
    transform: translateY(-2px);
    background-color: transparent;
}
@media (max-width: 780px) {
    .custom-wrapper {
        justify-content: center !important;
        align-items: center !important;
    }
}











  /*
  *************************/
  /*   Custom ScrollBar      */
  /*************************
  */
  
  ::-webkit-scrollbar {
    width: 12px;
  }
  
  
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
  }
  
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(192, 192,192, 0.9);
  
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background: rgba(169,169,169,0.8);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(192,192,192,1);
  }
  
  
  .section-header h3 {
    font-size: 32px;
    color: #111;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }
  
  .section-header h3::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-header h3::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #1498ec;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-header p {
    text-align: center;
    padding-bottom: 30px;
    color: #29588c;
    font-size: 1.4em;
  }