.bg-card {
    background-color: #ebebeb !important;

}

.portfolio .dlab-gallery-box {

    margin-bottom: 8px;
    margin-left: 11px;
    background: #ebebeb;
}
