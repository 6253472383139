.dlab-box{
    height: 100%;
}
.dlab-media{
    height: 100%;
    display: flex;
}

.dlab-post-title2{
    margin-top: 12px;
    margin-bottom: 25px;
    text-align: center;
}
.card-box{
    margin-bottom: 50px;
}
.dlab-media img, .dlab-post-media img{
    width: 100%;
    height: 250px !important;
    object-fit: contain;
}